<template>
  <div class="society">
    <Header/>
    <div class="content">
      <div class="title">市域社会治理</div>
      <div class="title-content">采用大数据技术对市域社会治理各维度数据进行深入挖掘和综合分析。深化社会治理、公共服务、民生服务、应急事件响应等应用，强化市域社会治理决策的前瞻性和科学性，使“经验治理”向“精准治理”跨越。</div>
      <div style="text-align:center;">
        <img
        class="content_img"
        src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/detail_sh.png"
        alt="智慧园区解决方案"
      />
      </div>
      <h4 class="shining">功能亮点</h4>
      <div class="service_wrap">
        <div v-for="item in service" :key="item.title" class="service-item">
          <div class="service-box">
            <div class="h-text">{{ item.title }}</div>
            <img class="position_img" :src="item.imgUrl" :alt="item.title" />
          </div>
          <div class="des">
            {{ item.des }}
          </div>
        </div>
      </div>
      <div class="footer" @click="jumpTo">下一篇：智慧社区解决方案 >></div>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  export default {
    name: 'Society',
    components: { Header },
    data() {
      return {
        service: [
        {
          title: '全面感测',
          des:
            '通过公司提供的多项安全智能管理解决方案，实现对城市各相关单位核心系统安全运行的测量、监控和分析，保障城市公共系统运行的安全和稳定。',
          imgUrl: require('../../static/images/gn_1.png'),
        },
        {
          title: '快速响应',
          des:
            '遍布各处的智能传感器和智能设备组成“物联网”，从云端实现城市多场景多设备状态的实时监控，出现异常时可通过多种方式主动上报故障信息，提高故障处理的快速响应能力。',
          imgUrl: require('../../static/images/gn_2.png'),
        },
        {
          title: '综合分析',
          des:
            '可实现多平台之间数据的互联，并通过多维度数据综合分析帮助客户提前预判故障风险做出预警，减少故障发生的概率，避免安全事故的发生。',
          imgUrl: require('../../static/images/gn_3.png'),
        },
        {
          title: '智慧应用',
          des:
            '基于润一智慧安全数据云形成强大的边缘计算体系，实现对平台数据的汇聚融合分析，为城市公共安全的智慧化管理提供强大的数据支撑，实现城市公共安全的正常运行和持续发展。\n',
          imgUrl: require('../../static/images/gn_4.png'),
        },
      ]
      }
    },
    methods: {
      jumpTo() {
        this.$router.push('/details/community')
      }
    }
  }
</script>

<style lang="scss" scoped>
// .content{
//   padding: 0.81rem 0.41rem 0.25rem 0.41rem ;
// }
.title{
  font-size: 0.4rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.75rem;
}
.title-content{
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  font-size: 0.37rem;
  margin-bottom: 0.91rem;
}
.content_img{
  width: 9.15rem;
  height: 4.88rem;
}
.service-box{
  display: flex;
  justify-content: space-between;
}
.h-text{
  font-size: 0.35rem;
  font-weight: 600;
  color: #FF9D0A;
}
.shining{
  font-size: 0.4rem;
  color: rgba(39, 32, 27, 1);
  margin-top: 1.08rem;
  margin-bottom: 0.89rem;
}
.position_img{
  width: 0.82rem;
  height: 0.73rem;
  margin-right: 0.12rem;
}
.des{
  width: 100%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #333333;
  margin-right: 0;
  line-height: 0.5rem;
}
.footer{
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-bottom: 0.3rem;
}
.service-item{
  padding: 0.2rem;
  margin-bottom: 0.49rem;
}
</style>